import React from "react"
import { Link } from "gatsby"

// services images
import arrow from "../../../assets/images/servicesPages/arrowDown.png"
import sofia from "../../../assets/images/blog/sofia.png"
import yahoo from "../../../assets/images/blog/yahoo.png"
import invest from "../../../assets/images/blog/invest/invest.jpg"


class OurNews extends React.Component {
    
    clickOnMouse = () => {
        const evt = document.createEvent('MouseEvents')

        evt.initEvent('wheel', true, true)
        evt.deltaY = +120
        window.dispatchEvent(evt);
    }

    render(){
        return(
            <div id="our-news" onClick={this.clickOnMouse} className="home-page-slide">
                <div id="top-part">
                    <div id="top-part-content">
                        <h2>Our News</h2>
                        <div className="line"></div>
                    </div>
                    <Link to="/blog" className="news-btn">All News</Link>                    
                </div>
                <div id="bottom-part">
                <Link className="article" to="/blog/drupal10">
                        <img src={yahoo} className="article-img" alt="Sofia"/>
                        <div className="yellow-line"></div>
                        <div className="article-date">27 October 2022</div>
                        <h5>Ready for Drupal 10? Check Your Sites Readiness for 8 New Features.</h5>
                        <p>In December, 2022 Drupal will have another major release. As Drupal developers maintaining platforms for many organizations, big and small...</p>
                    </Link>
                    <img className="arrow-img" src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>
                <Link className="article" to="/blog/crypto-casino">
                        <img src={yahoo} className="article-img" alt="Sofia"/>
                        <div className="yellow-line"></div>
                        <div className="article-date">20 October 2022</div>
                        <h5>Web3 & The €150 Billion Crypto Gambling Business</h5>
                        <p> Web3 is a future, decentralized phase of internet development, where users become owners.</p>
                    </Link>
                    <img className="arrow-img" src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>
                    <Link className="article" to="/blog/nearshoring-bulgaria">
                        <img src={yahoo} className="article-img" alt="Sofia"/>
                        <div className="yellow-line"></div>
                        <div className="article-date">28 January 2022</div>
                        <h5>Bulgaria As The Perfect Nearshore Development Destination [2022 GUIDE]</h5>
                        <p>Investing in nearshore software development has become the future of business. Whether it is the ongoing Covid-19 crisis...</p>
                    </Link>
                    
                </div>
            </div>
        )
    }

}

export default OurNews