import React from "react"
//import { Link } from "gatsby"

// services images
import arrow from "../../../assets/images/vertical-yellow-arrow.png"

import actelion from "../../../assets/images/clients/actelion.png"
import ceibs from "../../../assets/images/clients/ceibs.png"
import cs from "../../../assets/images/clients/credit_suisse.png"
import frankfurt from "../../../assets/images/clients/frankfurt.png"

import jj from "../../../assets/images/clients/johnson_and_johnson.png"
import vifor from "../../../assets/images/clients/vifor.png"
import galderma from "../../../assets/images/clients/galderma.png"
import havas from "../../../assets/images/clients/havas.png"

import ipg from "../../../assets/images/clients/ipg.png"
import lwf from "../../../assets/images/clients/lwf.png"
import veltassa from "../../../assets/images/clients/veltassa.png"
import rayaldee from "../../../assets/images/clients/Rayaldee.png"

class OurClients extends React.Component {

    clickOnMouse = () => {
        const evt = document.createEvent('MouseEvents')

        evt.initEvent('wheel', true, true)
        evt.deltaY = +120
        window.dispatchEvent(evt);
    }
    
    render(){
        return(
            <div id="our-clients" onClick={this.clickOnMouse} className="home-page-slide">
                <div id="top-part">
                    <div id="left-part">
                        <h2>Our Clients</h2>
                        <div className="line"></div>
                        <p>Our clients are global leaders in their fields across all industries, including finance, pharma, life science, and education. Innovative new businesses start-ups turn to us for our ability to position them as leaders, and support their success.</p>
                        {/* <Link to="/clients">View All</Link> */}
                    </div>
                    <img src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>
                    <h3>These companies have all come to rely on us as a <span>valuable</span>, long-term <span>partner</span> working to help <span>grow</span> their <span>business</span>.</h3>
                </div>
                <div id="bottom-part">
                    <div className="row">
                        <img src={actelion} alt="Drupal and WordPress Development Company, ICON Worldwide, Actelion" title="ICON Worldwide Drupal and WordPress Development Company, Actelion"/>   
                        <img src={ceibs} alt="Drupal and WordPress Development Company, ICON Worldwide, CEIBS" title="ICON Worldwide Drupal and WordPress Development Company, CEIBS"/>
                        <img src={cs} alt=" Drupal and WordPress Development Company, ICON Worldwide, Credit Suisse" title="ICON Worldwide Drupal and WordPress Development Company, Credit Suisse"/>
                        <img src={frankfurt} alt=" Drupal and WordPress Development Company, ICON Worldwide, Frankfurt School" title="ICON Worldwide Drupal and WordPress Development Company, Frankfurt School"/>
                    </div>
                    <div className="row">
                        <img src={jj} alt=" Drupal and WordPress Development Company, ICON Worldwide, Johnson and Johnson" title="ICON Worldwide Drupal and WordPress Development Company, Johnson and Johnson"/>
                        <img src={vifor} alt="Drupal and WordPress Development Company, ICON Worldwide, Vifor" title="ICON Worldwide Drupal and WordPress Development Company, Vifor"/>
                        <img src={galderma} alt=" Drupal and WordPress Development Company, ICON Worldwide, Galderma" title="ICON Worldwide Drupal and WordPress Development Company, Galderma"/>
                        <img src={havas} alt="Drupal and WordPress Development Company, ICON Worldwide, Havas" title="ICON Worldwide Drupal and WordPress Development Company, Havas"/>
                    </div>
            
                    <div className="row">
                        <img src={ipg} alt=" Drupal and WordPress Development Company, ICON Worldwide, IPG" title="ICON Worldwide Drupal and WordPress Development Company, IPG"/>
                        <img src={lwf} alt="Drupal and WordPress Development Company, ICON Worldwide, LWF" title="ICON Worldwide Drupal and WordPress Development Company, LWF"/>
                        <img src={veltassa} alt=" Drupal and WordPress Development Company, ICON Worldwide, Veltassa" title="ICON Worldwide Drupal and WordPress Development Company, Veltassa"/>
                        <img src={rayaldee} alt="Drupal and WordPress Development Company, ICON Worldwide, Rayaldee" title="ICON Worldwide Drupal and WordPress Development Company, Rayaldee"/>
                    </div>
                </div>
            </div>
        )
    }

}

export default OurClients