import React from "react"
import { Link } from "gatsby"

// services images
// import arrow from "../../../assets/images/servicesPages/arrowDown.png"
// import icon from "../../../assets/images/blog/icon.png"
// import sofia from "../../../assets/images/blog/sofia.png"
// import yahoo from "../../../assets/images/blog/yahoo.png"

import pf from "../../../assets/images/works/teasers/pf.png"
import idday from "../../../assets/images/works/teasers/idday.png"
import fs from "../../../assets/images/works/teasers/frankfurt.png"

import nuyu from "../../../assets/images/works/teasers/nuyu.png"
import ceibs from "../../../assets/images/works/teasers/ceibs.png"
import ida from "../../../assets/images/works/teasers/iron-deficiency.png"


class ourWork extends React.Component {
    
    clickOnMouse = () => {
        const evt = document.createEvent('MouseEvents')

        evt.initEvent('wheel', true, true)
        evt.deltaY = +120
        window.dispatchEvent(evt);
    }

    render(){
        return(
            <div id="our-work" onClick={this.clickOnMouse} className="home-page-slide">

                <div className="top-part">
                    <h2>Our Work</h2>
                    <div className="line"></div>
                    <Link className="work-btn" to="/works">View All</Link>
                </div>

                <div className="bottom-part">
                <Link to="/works/project-firefly">
                        <div className="teaser">
                            <img src={pf} alt="Drupal and WordPress Development Company, ICON Worldwide" title="ICON Worldwide Drupal and WordPress Development Company, case studies"/>
                            <div className="title">
                                <p>Project Firefly</p>
                            </div>
                        </div>
                    </Link>

                    <Link to="/works/iron-deficiency-day">
                        <div className="teaser">
                            <img src={idday} alt="Drupal and WordPress Development Company, ICON Worldwide" title="ICON Worldwide Drupal and WordPress Development Company, case studies"/>
                            <div className="title">
                                <p>Iron Deficiency Day</p>
                            </div>
                        </div>
                    </Link>

                    <Link to="/works/frankfurt-school" className="mobile">
                        <div className="teaser">
                            <img src={fs} alt="Drupal and WordPress Development Company, ICON Worldwide" title="ICON Worldwide Drupal and WordPress Development Company, case studies"/>
                            <div className="title">
                                <p>Frankfurt School</p>
                            </div>
                        </div>
                    </Link>

                    <Link to="/works/nuyu" className="mobile"> 
                        <div className="teaser">
                            <img src={nuyu} alt="Drupal and WordPress Development Company, ICON Worldwide" title="ICON Worldwide Drupal and WordPress Development Company, case studies"/>
                            <div className="title">
                                <p>NuYu</p>
                            </div>
                        </div>
                    </Link>

                    <Link to="/works/ceibs" className="mobile">
                        <div className="teaser">
                            <img src={ceibs} alt="Drupal and WordPress Development Company, ICON Worldwide" title="ICON Worldwide Drupal and WordPress Development Company, case studies"/>
                            <div className="title">
                                <p>CEIBS</p>
                            </div>
                        </div>
                    </Link>

                    <Link to="/works/iron-deficiency" className="mobile">
                        <div className="teaser">
                            <img src={ida} alt="Drupal and WordPress Development Company, ICON Worldwide" title="ICON Worldwide Drupal and WordPress Development Company, case studies"/>
                            <div className="title">
                                <p>Iron Deficiency</p>
                            </div>
                        </div>
                    </Link>
                </div>

            </div>
        )
    }

}

export default ourWork