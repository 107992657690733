import React from "react"
import { Scroller, Section } from 'react-fully-scrolled';
import SEO from "../components/seo"
//import WelcomeAnimation from "../components/homePage/welcomeAnimation"
import ServicesSlide from "../components/homePage/servicesSlide"
import ServicesSlide2 from "../components/homePage/servicesSlide_2"
import OurClients from "../components/homePage/ourClients"
import AboutUs from "../components/homePage/aboutUs"
import OurWork from "../components/homePage/ourWork"
import OurNews from "../components/homePage/ourNews"
import Footer from "../components/footer"
import CookieBar from "../components/cookieBar"
import ServiceMenu from "../components/services/servicesMenuHeader"


export default () => (
    <div>
        <SEO title={'ICON Worldwide | Remote Development Teams: Drupal, Java, React, Angular and more...'}
        description="Expert development teams for web, mobile, software, and more."
        canonical={'https://icon-worldwide.com'} />
        {/* <WelcomeAnimation/> */}
        <div id="mobile-menu" className="home-mobile"><ServiceMenu/></div>
        <CookieBar/>
        <Scroller
            curPage={1}
            onBeforeScroll={(from, to) => {}}
            onAfterScroll={(page) => {}}
            isEnabled={true}>

            <Section><ServicesSlide/></Section>
            <Section><ServicesSlide2/></Section>
            <Section><OurClients/></Section>
            <Section><AboutUs/></Section>
            <Section><OurWork/></Section>
            <Section><OurNews/></Section>
            <Section><Footer noScrollbar="false"/></Section>
        </Scroller>
    </div>
)
