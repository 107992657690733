import React from "react"
import { Link } from "gatsby"
import arrow from "../../../assets/images/servicesPages/arrowDown.png"

class AboutUs extends React.Component {
  clickOnMouse = () => {
    const evt = document.createEvent('MouseEvents')

    evt.initEvent('wheel', true, true)
    evt.deltaY = +120
    window.dispatchEvent(evt);
  }

  render() {
    return (
      <div id="aboutus" onClick={this.clickOnMouse}>
        <div id="image-about-us">
          <div id="title">
            <h2>About Us</h2>
            <div id="bottom-border"></div>
          </div>    
        </div>
        <div className="bottom-container">
            <div className="our-team-text">
             <p> Our team is comprised of <span>digital </span>
              product <span>strategists, user experience </span>
              designers and <span>top-tier</span> software
              <span> engineers.</span></p> 
              <Link to="/about"><button id="learn-more">Learn More</button></Link>                    
            </div>                     
        <img id ="arrow" src={arrow} alt="Drupal and WordPress Development Company arrow, ICON Worldwide" title="ICON Worldwide Drupal and WordPress Development Company, arrow"/>   
          <div className="rightColumn">
            <div className="statistics">
              <h2>5</h2>
              <p>Offices</p>
            </div>
            <div className="statistics">
              <h2>6</h2>
              <p>Awards</p>
            </div>
            <div className="statistics">
              <h2>12</h2>
              <p>Years Old</p>
            </div>
            <div className="statistics">
              <h2>33</h2>
              <p>Employees</p>
            </div>
            <div className="statistics">
              <h2>500+</h2>
              <p>Apps</p>
            </div>
            <div className="statistics">
              <h2>2000+</h2>
              <p>Digital Projects</p>
            </div>
        </div>
      </div> 
      </div>
    )
  }
}

export default AboutUs
