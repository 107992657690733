import React from "react"
import { Link } from "gatsby"
import Header from "../../components/header"
// services images
import DesignImg from "../../../assets/images/services_yellow_icons/design.svg"
import StrategyImg from "../../../assets/images/services_yellow_icons/strategy.svg"
import WebsiteImg from "../../../assets/images/services_yellow_icons/website.svg"
import MobileImg from "../../../assets/images/services_yellow_icons/mobile.svg"
import SeoImg from "../../../assets/images/services_yellow_icons/seo.svg"
import SemImg from "../../../assets/images/services_yellow_icons/sem.svg"
import DevelopmentImg from "../../../assets/images/services_yellow_icons/development.svg"
import OutsourcingImg from "../../../assets/images/services_yellow_icons/outsourcing.svg"

class servicesSlide extends React.Component {

    clickOnMouse = () => {
        const evt = document.createEvent('MouseEvents')

        evt.initEvent('wheel', true, true)
        evt.deltaY = +120
        window.dispatchEvent(evt);
    }

    render(){
        return(
            <div id="slide-services" className="home-page-slide" onClick={this.clickOnMouse} tabIndex={0}>
                <Header/>
                <div className="home-page-slide-inner">

                    <div id="slide-services-left">
                        <h1>We Deliver on the Promise of <span>Web3</span> & <span>The Blockchain</span>.</h1>
                        <p>ICON Worldwide is an award-winning development agency.  From Web2 to Web3 and the Blockchain, we help brands deliver on their vision.</p>
                    </div>

                    <div id="slide-services-right">
                        <div id="yellow-line"></div>

                        <div id="link-box">
                            <Link id="design" to="/services/design" className="design link">
                                <img src={DesignImg} alt="Drupal and WordPress Development Company, ICON Worldwide, Design" title="Drupal and WordPress Development Company, ICON Worldwide, Design"/>
                                <p>Design</p>
                            </Link>
                            <Link id="strategy" to="/services/marketing-strategy" className="strategy link">
                                <img src={StrategyImg} alt="Drupal and WordPress Development Company, ICON Worldwide, strategy" title="Drupal and WordPress Development Company, ICON Worldwide, strategy"/>
                                <p>Strategy</p>
                            </Link>
                            <Link id="website" to="/services/website-development" className="website link">
                                <img src={WebsiteImg} alt="Drupal and WordPress Development Company, ICON Worldwide, website" title="Drupal and WordPress Development Company, ICON Worldwide, website"/>
                                <p>Website</p>
                            </Link>
                            <Link id="mobile" to="/services/mobile-development" className="mobile link">
                                <img src={MobileImg} alt="Drupal and WordPress Development Company, ICON Worldwide, mobile" title="Drupal and WordPress Development Company, ICON Worldwide, mobile"/>
                                <p>Mobile</p>
                            </Link>
                            <Link id="software" to="/services/software-development" className="software link">
                                <img src={DevelopmentImg} alt="Drupal and WordPress Development Company, ICON Worldwide, development" title="Drupal and WordPress Development Company, ICON Worldwide, development"/>
                                <p>Technology</p>
                            </Link>
                            <Link id="it-outsourcing" to="/services/nearshore-software-development" className="it-outsourcing link">
                                <img src={OutsourcingImg} alt="Drupal and WordPress Development Company, ICON Worldwide, nearshore" title="Drupal and WordPress Development Company, ICON Worldwide, nearshore"/>
                                <p>Nearshore</p>
                            </Link>
                            <Link id="seo" to="/services/seo" className="seo link">
                                <img src={SeoImg} alt="Drupal and WordPress Development Company, ICON Worldwide, seo" title="Drupal and WordPress Development Company, ICON Worldwide, seo"/>
                                <p>SEO</p>
                            </Link>
                            <Link id="sem" to="/services/search-engine-marketing" className="sem link">
                                <img src={SemImg} alt="Drupal and WordPress Development Company, ICON Worldwide, sem" title="Drupal and WordPress Development Company, ICON Worldwide, sem"/>
                                <p>SEM</p>
                            </Link>
                        </div>

                    </div>
                </div>

                <div className="icon-scroll"></div>

                <div id="homepage-news-block">
                    <div id="homepage-news-block-inner">                      
                        <div className="homepage-article">
                        Blockchain                         
                        </div>
                        <div className="separator">/</div>
                        <div className="homepage-article">
                        DeFi                         
                        </div>
                        <div className="separator">/</div> 
                        <div className="homepage-article">
                        Web3                          
                        </div>
                        <div className="separator">/</div>
                        <div className="homepage-article">
                        NFTs                  
                        </div>
                        <div className="separator">/</div>
                        <div className="homepage-article">
                        Crypto                           
                        </div>
                        <div className="separator">/</div>
                        <div className="homepage-article">
                        Games                       
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default servicesSlide