import React from "react"
import { Link } from "gatsby"
import ServicesBottomNav from "../../components/services/servicesBottomNav"

// import ServiceMenu from "../../components/services/servicesMenuHeader"

import arrow from "../../../assets/images/vertical-yellow-arrow.png"

class servicesSlide_2 extends React.Component {

    clickOnMouse = () => {
        const evt = document.createEvent('MouseEvents')

        evt.initEvent('wheel', true, true)
        evt.deltaY = +120
        window.dispatchEvent(evt);
    }

    render(){
        return(
            <div id="slide-services-2" onClick={this.clickOnMouse} className="home-page-slide">   
            {/* <div id="services" class="different-pages">
                <ServiceMenu/>
            </div>            */}
                <div id="bg-img">
                    <div id="our-services">                   
                        <h2>Our Services</h2>
                        <div className="line"></div>
                        <Link to="/services">View All</Link>
                    </div>                
                    <div id="bg-img-inner-text">We know the <span>importance</span> of <span>design and technology</span> and believe that <span>interdisciplinary collaboration</span> is the key to <span>innovative products</span>.</div>
                </div>

                <img id="arrow" src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>

                <div id="bottom">
                    <ServicesBottomNav/>
                    <div id="yellow-line"></div>
                </div>
            </div>
        )
    }
}

export default servicesSlide_2